import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faPinterest,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import Disclaimer from "./disclaimer";
import { useNavigate, Route, Routes, Link } from "react-router-dom";
function FooterSocial() {
  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate("/disclaimer");
  };

  return (
    <div>
      <div className="disclaimerFooter cardDetails">
        <div className="col-12 text-center">
          <h3>*** DISCLAMER ***</h3>
          <p>
            *** DISCLAMER *** Satta-King-Black.com is your owner and retains all
            rights, with the exception of any alternate party posts, to its
            resources, content, facilities, information, and services and
            products along with graphics on the web site.
            <br />
            <a href="/">More Read</a>
          </p>
        </div>
      </div>
      <div className="socialIcon text-center">
        <div className="p-1">
          <span>Powered by</span>
          <h6>SATTA-KING-BLACK.COM</h6>

          <div style={{ marginLeft: "1rem" }} className="p-1">
            <a
              href="https://www.facebook.com/sattakingblack786"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faFacebook} size="2x" />
            </a>
            <a
              href="https://twitter.com/sattakingblack"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faTwitter} size="2x" />
            </a>
            <a
              href="https://in.pinterest.com/sattakingblack"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faPinterest} size="2x" />
            </a>
            <a
              href="https://www.instagram.com/sattakingblack"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faInstagram} size="2x" />
            </a>
          </div>
          <div className="navItem p-2">
            <span>
              <a href="/">Home</a>{" "}
            </span>{" "}
            |
            <span>
              <a href="/disclaimer"> Disclaimer</a>{" "}
            </span>{" "}
            |{" "}
            <span>
              <a href="/"> Site Map</a>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
export default FooterSocial;
