import "./App.css";
import moment from "moment";
import React, { useState, useEffect } from "react";
import Disclaimer from "./js/disclaimer";
import { useNavigate, useLocation } from "react-router-dom";
import trackVisitor from "./utilities/tracker";
import "moment-timezone";
import DaySattaResult from "./js/daySattaResult";
const momenttz = require("moment-timezone");

function App() {
  const location = useLocation();
  const isDisc = location.pathname.includes("/disclaimer");
  var currentDate = moment().tz("Asia/Kolkata").format("YYYY-MM-DD");

  const currentTime = moment().format("HH:mm");
  const [data, setData] = useState([]); // State to store data fetched from backend
  const [datagame, setDataFor] = useState([]); // State to store processed data for display
  var prevDate = moment()
    .subtract(1, "days")
    .tz("Asia/Kolkata")
    .format("YYYY-MM-DD");

  useEffect(() => {
    trackVisitor();
  }, []);

  useEffect(() => {
    fetch("https://api.sattakingvip.co.in/getData", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        game_name: "",
        curr_date: currentDate,
        prev_date: prevDate,
        open_time: "market_sunday_time_open",
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        // Sort data based on open_time
        const sortedData = json.sort((a, b) => {
          const timeA = moment(a.open_time, "HH:mm");
          const timeB = moment(b.open_time, "HH:mm");
          return timeA.diff(timeB);
        });

        // Set sorted data into state
        setData(sortedData);
      })
      .catch((error) => console.error(error));
  }, [currentDate, prevDate]);

  // page scroll
  function pageScroll() {
    const tableSection = document.getElementById("monthTable");
    if (tableSection) {
      tableSection.scrollIntoView({ behavior: "smooth" });
    }
  }

  useEffect(() => {
    if (data?.length > 0) {
      // Convert current time to a moment object for comparison
      const currentMoment = moment(currentTime, "HH:mm");

      // Process and filter the data
      const processedData = data.map((item) => {
        const itemTime = moment(item.open_time, "HH:mm");
        const resultAvailable = item?.curr_date?.result ? true : false;

        return {
          gameName: item.game_name,
          result: resultAvailable ? item?.curr_date?.result : "wait",
          openTime: item.open_time,
          isAvailable: resultAvailable,
          itemTime: itemTime,
        };
      });

      // Sort the processed data by open_time
      const sortedProcessedData = processedData.sort((a, b) => {
        return a.itemTime.diff(b.itemTime);
      });

      // Separate records into those with available results and those with "wait"
      const availableResults = sortedProcessedData.filter(
        (item) => item.isAvailable
      );
      const upcomingRecords = sortedProcessedData.filter(
        (item) => !item.isAvailable
      );

      // Determine the records to display
      let recordsToDisplay = [];

      if (availableResults.length > 0) {
        // Show available results and include records up to the next upcoming record
        recordsToDisplay = [...availableResults];

        const lastAvailableIndex = sortedProcessedData.indexOf(
          availableResults[availableResults.length - 1]
        );
        const nextRecord = sortedProcessedData[lastAvailableIndex + 1];
        if (nextRecord) {
          recordsToDisplay.push(nextRecord);
        }
      } else {
        // No available results, show up to 3 upcoming records with "wait"
        recordsToDisplay = [...upcomingRecords.slice(0, 3)];
      }

      // Ensure only 3 records are shown
      if (recordsToDisplay.length > 3) {
        // Remove the oldest record if more than 3 records are present
        recordsToDisplay = recordsToDisplay.slice(-3);
      }

      // Update state with the processed and limited data
      setDataFor(recordsToDisplay);

      // Debugging log
    }
  }, [data, currentTime]);
  return (
    <div className="App">
      <div className="col-12 homeHeader">
        <div className="row main-row">
          <div className=" col-sm-4 col-md-4 text-center text-danger fw-b link">
            <a href="/">HOME</a>
          </div>
          <div className="col-sm-4 col-md-4 text-center text-danger fw-b link">
            <a href="http://sattasport.co/">SATTA KING</a>
          </div>
          <div className="col-sm-4 col-md-4 text-center text-danger fw-b link ">
            <a onClick={pageScroll}>SATTA RESULT</a>
          </div>
        </div>
      </div>
      <marquee className=" marquee-1">
        {" "}
        Satta number, black satta king sss, satta com, gali satta result, satta
        king live result, gali result, satta king live, satta king disawar,
        black satta 786, satta king gali, satta king com, gali satta chart,
        disawar result, satta disawar, satta live result, disawar chart, gali
        chart, satta king bazar, satta king desawar,{" "}
      </marquee>
      <div className="col-12 text-center header-heading">
        <h6>SATTA KING | SATTA KING 786 | DELHI SATTA KING</h6>
        <h1>SATTA KING BLACK</h1>
      </div>
      <marquee className=" marquee-2">
        "Satta King" is a term used to describe betting on numbers, usually in
        the context of illegal lottery or gambling activities in India. The
        origin of the term "Satta King" is believed to come from the Hindi
        language, where "satta" means betting or gambling, and "king" refers to
        the person who has won or is believed to be the best in the betting
        activity. The practice of satta has been illegal in India for many years
        and is widely considered to be a form of organized crime. Participating
        in satta can be risky and lead to financial losses, and is also often
        associated with other criminal activities such as money laundering and
        extortion.
      </marquee>

      <div className="col-12 text-white text-center p-1">
        <em className="">
          INDIA NO.1 SATTA KING SITE SATTASPORT HEARTILY WELCOME. HERE YOU MAY
          GET EXCELLENT ESTIMATION BY PRIME INDIVIDUAL AND QUICK MATKA RESULT.
          GALI DISAWAR FIX SINGLE JODI KE LIYE HERE YOU FIND TOP SATTA MARKET OF
          INDIA FARIDABAD GHAZIABAD GALI DESAWAR SATTA TIPS FAST SATTA RESULT
          GALI DISAWAR GHAZIABAD FARIDABAD SATTA GUESSING POWERED BY
          SATTASPORT.CO
        </em>
      </div>

      {/* advertisment section need to create its pending*/}

      <div className="advertisement">
        <h3>advertisement heading</h3>
        <p>advertisement content</p>
      </div>

      <div className="text-center currentBanner mt-3">
        {datagame?.map((todayData, index) => (
          <div key={index} className="game">
            <h1 className="mb-0">{todayData?.gameName}</h1>
            <p className="  col-md-12">
              <div className=" blinking-text">{todayData?.result || ""} </div>
            </p>
          </div>
        ))}
      </div>

      <DaySattaResult dayGameData={data} />
      {isDisc && <Disclaimer style={{ display: "none" }} />}
    </div>
  );
}

export default App;
